import Vue from 'vue'
import store from '@/store'
import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_METRICAS);

class Metricas_Config {
  obtener_configuraciones(options) {
    return http.get(utils.url_options(`/configuracion`,options));
  }

  obtener_config(id) {
    return http.get(`/configuracion/${id}`);
  }

  crear_config(payload) {
    return http.post(`/configuracion`,payload);
  }

  editar_config(id, payload) {
    return http.put(`/configuracion/${id}`, payload);
  }

  eliminar_config(id) {
    return http.delete(`/configuracion/${id}`);
  }
}

export default new Metricas_Config();